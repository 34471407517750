import { Component, OnInit, Inject } from '@angular/core';
import * as Redux from 'redux';
import { AppStore } from '../../../store/store';
import mixins from "../../../mixins";
let $ = globalThis.$;

@Component({
  selector: 'app-login-modal',
  templateUrl: './login-modal.component.html',
  styleUrls: ['./login-modal.component.scss']
})

export class LoginModalComponent implements OnInit {

  constructor(@Inject(AppStore) private store: Redux.Store) {

  }

  ngOnInit(): void {
    globalThis.LoginModal = this;
  }

  onSubmit(formObj) {
    let self: any = this;
    console.log("onSubmit", formObj);
    $.post("/API/user/Login", formObj.form.value).then(res => {
      if (res.status) {
        let data = { ...res.data, ...formObj.form.value };
        self.setStore({ name: "currentUser", value: data });
        $("#loginRegisterModal button.close").click();
      } else {
        alert("Login Failed");
      }
    }).catch(e => {
      alert("Login Failed Please Try agin Later");
    });
  }

}
mixins.connect(LoginModalComponent);