<!-- Start Category Area -->
<div class="container">
  <div class="section-title" *ngFor="let Title of sectionTitle">
    <h2>{{ Title.title }}</h2>
    <p>{{ Title.paragraph }}</p>
  </div>

  <div class="row">
    <div
      class="col-lg-2 col-sm-6 col-md-4"
      *ngFor="let Content of state.category_count"
    >
      <div class="single-category-box">
        <div class="icon">
          <i class="{{ Content.icon }}"></i>
        </div>
        <h3>{{ Content.title }}</h3>
        <span>{{ Content.numberOfPlaces }}</span>
        <a routerLink="/{{ Content.link }}" class="link-btn"></a>
      </div>
    </div>
  </div>
</div>
<!-- End Category Area -->
