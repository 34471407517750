<div
  class="adsense-column d-none d-xxl-block"
  [ngStyle]="{
    right: position == 'right' ? '0px' : 'auto',
    left: position == 'left' ? '0px' : 'auto'
  }"
>
  <section *ngFor="let item of ads; index as i" class="adsense-100X300 adsense">
    <ng-adsense
      [adClient]="'ca-pub-7640562161899788'"
      [adSlot]="2930227358"
      [display]="'inline-block'"
      [width]="320"
      [height]="108"
    ></ng-adsense>
  </section>
</div>
