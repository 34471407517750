import { Component, OnInit, Inject } from '@angular/core';
import * as Redux from 'redux';
import { AppStore } from '../../../store/store';
import mixins from "../../../mixins";
let $ = globalThis.$;

@Component({
  selector: 'app-home-demo-two',
  templateUrl: './home-demo-two.component.html',
  styleUrls: ['./home-demo-two.component.scss']
})
export class HomeDemoTwoComponent implements OnInit {

  constructor(@Inject(AppStore) private store: Redux.Store) {
    
  }

  ngOnInit(): void {
    globalThis.Home = this;
    this.setData();
  }

  setData() {
    
  }

}

mixins.connect(HomeDemoTwoComponent);
