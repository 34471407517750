<!-- Start Copyright End -->
<div class="copyrights-area">
    <div class="row align-items-center">
        <div class="col-lg-6 col-sm-6 col-md-6">
            <p><i class='bx bx-copyright'></i>2021 <a routerLink="/dashboard">Vesax</a>. All rights reserved</p>
        </div>
        <div class="col-lg-6 col-sm-6 col-md-6 text-right">
            <p>Designed by ❤️ <a href="https://envytheme.com/" target="_blank">EnvyTheme</a></p>
        </div>
    </div>
</div>
<!-- End Copyright End -->