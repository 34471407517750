import { Component, Inject, OnInit, Injectable, Optional } from '@angular/core';
import { AppStore } from '../store/store';
import * as Redux from 'redux';

globalThis.AppStore = AppStore;
globalThis.Redux = Redux;

const setObjects = function (storeObject, payload) {
    let arr = payload.name.split(".");
    if (arr.length > 1) {
        let tmp = storeObject, key = "", old = null;
        arr.forEach(k => {
            old = tmp, tmp = tmp[k], key = k;
        });
        old[key] = payload.value;
    } else {
        storeObject[payload.name] = payload.value;
    }
    return { ...storeObject };
};

export const Store = AppStore;

type Constructor = new (...a: any[]) => any;
type Merge<TTrait extends Constructor, TTarget extends Constructor> =
    (new (...a: ConstructorParameters<TTarget>) => InstanceType<TTrait> & InstanceType<TTarget>) & Pick<TTarget, keyof TTarget> & Pick<TTrait, keyof TTrait>

export const trait = <TTrait extends Constructor>(Orig: TTrait) =>
    <TTarget extends Constructor>(Tgt: TTarget): Merge<TTrait, TTarget> => {
        // perform patching 
        return Tgt as any; // assertion required
    }

@Injectable({
    providedIn: 'root'
})

export class Mixins {
    log: Function = console.log;
    getStore: Function = function () { return this.store.getState().root; }
    constructor(@Inject(AppStore) private store: Redux.Store) {
        console.log("Mixins", this, parent);
    }
    setStore: Function = function (payload) {
        this.store.dispatch({
            type: (state) => {
                return setObjects(state, payload);
            }
        });
    }
}

export const $ = globalThis.$;

export default {
    connect(classname) {
        Object.assign(classname.prototype, this);
        return classname;
    },
    getStore() {
        return this.store.getState().root;
    },
    setStore(payload) {
        this.store.dispatch({
            type: (state) => {
                return setObjects(state, payload);
            }
        });
    },
    log: console.log
}