<app-navbar-style-two></app-navbar-style-two>

<app-hometwo-banner></app-hometwo-banner>

<app-hometwo-listings></app-hometwo-listings>

<section class="category-area pt-100 pb-70">
  <app-category></app-category>
</section>

<!-- <app-hometwo-destinations></app-hometwo-destinations> -->

<!-- <section class="feedback-area bg-image ptb-100">
  <app-feedback-style-one></app-feedback-style-one>
</section> -->

<app-hometwo-events></app-hometwo-events>

<!-- <section class="how-it-works-area pt-100 pb-70 bg-f9f9f9">
  <app-how-it-works></app-how-it-works>
</section> -->

<app-hometwo-blog></app-hometwo-blog>

<!-- <app-app-download></app-app-download> -->

<app-footer-style-one></app-footer-style-one>

<app-ad-columns></app-ad-columns>
<app-ad-columns position="right"></app-ad-columns>
