<div class="vesax-register">
  <div class="register-with-account">
    <span>Register with</span>
    <!-- <ul>
      <li>
        <a href="#" class="facebook"
          ><i class="bx bxl-facebook"></i> Facebook</a
        >
      </li>
      <li>
        <a href="#" class="twitter"><i class="bx bxl-twitter"></i> Twitter</a>
      </li>
    </ul>
  </div>
  <span class="sub-title"><span>Or Register with</span></span> -->
    <form #register="ngForm" (ngSubmit)="onSubmit(register)">
      <div class="form-group">
        <input
          ngModel
          name="name"
          type="text"
          placeholder="Name"
          class="form-control"
        />
      </div>
      <div class="form-group">
        <input
          ngModel
          name="email"
          type="email"
          placeholder="Email"
          class="form-control"
        />
      </div>
      <div class="form-group">
        <input
          ngModel
          name="pass"
          type="password"
          placeholder="Password"
          class="form-control"
        />
      </div>
      <div class="form-group">
        <input
          ngModel
          name="mobile"
          type="tel"
          placeholder="Mobile Number"
          class="form-control"
        />
      </div>
      <button type="submit">Register Now</button>
    </form>
    <span class="already-account"
      >Already have an account? <a href="#">Login Now</a></span
    >
  </div>
</div>
