import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
let $ = globalThis.$;

@Component({
  selector: 'app-register-model',
  templateUrl: './register-model.component.html',
  styleUrls: ['./register-model.component.scss']
})
export class RegisterModelComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  onSubmit(formObj) {
    console.log("onSubmit", formObj);
    let self: any = this;
    $.post("/API/user/Add_user", {
      ...formObj.form.value,
      type: 'public'
    }).then(res => {
      if (res.status) {
        if (res.status) {
          self.setStore({ name: "currentUser", value: formObj.form.value });
          $("#loginRegisterModal button.close").click();
        } else {
          alert("Register Failed");
        }
      }
    });
  }

}
