<!-- Start Footer Area -->
<footer class="footer-area bg-f5f5f5">
  <div class="container">
    <div class="row">
      <div class="col-lg-3 col-sm-6 col-md-6">
        <div class="single-footer-widget">
          <h3>About</h3>

          <ul class="link-list">
            <li>
              <a routerLink="/"
                ><i class="flaticon-left-chevron"></i> About desiFinds</a
              >
            </li>
            <li>
              <a routerLink="/"
                ><i class="flaticon-left-chevron"></i> Careers</a
              >
            </li>
            <li>
              <a routerLink="/"
                ><i class="flaticon-left-chevron"></i> Recent News</a
              >
            </li>
            <li>
              <a routerLink="/"
                ><i class="flaticon-left-chevron"></i> Investor Relations</a
              >
            </li>
            <li>
              <a routerLink="/"
                ><i class="flaticon-left-chevron"></i> Content Guidelines</a
              >
            </li>
            <li>
              <a routerLink="/"
                ><i class="flaticon-left-chevron"></i> Terms of Service</a
              >
            </li>
            <li>
              <a routerLink="/"
                ><i class="flaticon-left-chevron"></i> Privacy Policy</a
              >
            </li>
          </ul>
        </div>
      </div>

      <div class="col-lg-3 col-sm-6 col-md-6">
        <div class="single-footer-widget">
          <h3>Discover</h3>

          <ul class="link-list">
            <li>
              <a routerLink="/"
                ><i class="flaticon-left-chevron"></i> Project Cost Guides</a
              >
            </li>
            <li>
              <a routerLink="/"
                ><i class="flaticon-left-chevron"></i> Upcoming Events</a
              >
            </li>
            <li>
              <a routerLink="/"
                ><i class="flaticon-left-chevron"></i> Mobile App</a
              >
            </li>
            <li>
              <a routerLink="/"
                ><i class="flaticon-left-chevron"></i> Customer Support</a
              >
            </li>
            <li>
              <a routerLink="/"
                ><i class="flaticon-left-chevron"></i> Developers</a
              >
            </li>
            <li>
              <a routerLink="/"
                ><i class="flaticon-left-chevron"></i> Collections</a
              >
            </li>
            <li>
              <a routerLink="/"
                ><i class="flaticon-left-chevron"></i> Our Blog</a
              >
            </li>
          </ul>
        </div>
      </div>

      <div class="col-lg-3 col-sm-6 col-md-6">
        <div class="single-footer-widget">
          <h3>Business With desiFinds</h3>

          <ul class="link-list">
            <li>
              <a routerLink="/"
                ><i class="flaticon-left-chevron"></i> Claim your Business</a
              >
            </li>
            <li>
              <a routerLink="/"
                ><i class="flaticon-left-chevron"></i> Advertise on desiFinds</a
              >
            </li>
            <li>
              <a routerLink="/"
                ><i class="flaticon-left-chevron"></i> Restaurant Owners</a
              >
            </li>
            <li>
              <a routerLink="/"
                ><i class="flaticon-left-chevron"></i> Business Success
                Stories</a
              >
            </li>
            <li>
              <a routerLink="/"
                ><i class="flaticon-left-chevron"></i> Business Support</a
              >
            </li>
            <li>
              <a routerLink="/"
                ><i class="flaticon-left-chevron"></i> Blog for Business</a
              >
            </li>
            <li>
              <a routerLink="/"
                ><i class="flaticon-left-chevron"></i> Privacy Policy</a
              >
            </li>
          </ul>
        </div>
      </div>

      <div class="col-lg-3 col-sm-6 col-md-6">
        <div class="single-footer-widget">
          <h3>Contact Info</h3>

          <ul class="footer-contact-info">
            <li>
              <i class="bx bx-map"></i>175 5th Ave Premium Area, New York, NY
              10010, United States
            </li>
            <li>
              <i class="bx bx-phone-call"></i
              ><a href="tel:+11234567890">+1 (123) 456 7890</a>
            </li>
            <li>
              <i class="bx bx-envelope"></i
              ><a href="mailto:hello@vesax.com">hello@vesax.com</a>
            </li>
            <li>
              <i class="bx bxs-inbox"></i
              ><a href="tel:+557854578964">+55 785 4578964</a>
            </li>
          </ul>

          <ul class="social-link">
            <li>
              <a href="#" class="d-block" target="_blank"
                ><i class="bx bxl-facebook"></i
              ></a>
            </li>
            <li>
              <a href="#" class="d-block" target="_blank"
                ><i class="bx bxl-twitter"></i
              ></a>
            </li>
            <li>
              <a href="#" class="d-block" target="_blank"
                ><i class="bx bxl-instagram"></i
              ></a>
            </li>
            <li>
              <a href="#" class="d-block" target="_blank"
                ><i class="bx bxl-linkedin"></i
              ></a>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="copyright-area">
      <p>
        Copyright @2021 <span>Vesax</span> is Proudly Crafted by
        <a href="https://envytheme.com/" target="_blank">EnvyTheme</a>
      </p>
    </div>
  </div>

  <div class="footer-image text-center">
    <img src="assets/img/footer-image.png" alt="image" />
  </div>
</footer>
<!-- End Footer Area -->

<div class="go-top"><i class="bx bx-up-arrow-alt"></i></div>
