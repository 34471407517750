import { Component, Inject, OnInit } from '@angular/core';
import mixins from 'src/app/mixins';
import { AppStore } from 'src/app/store/store';
import * as Redux from 'redux';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
//import * as $ from 'jquery';
let $ = globalThis.$;
@Component({
    selector: 'app-hometwo-banner',
    templateUrl: './hometwo-banner.component.html',
    styleUrls: ['./hometwo-banner.component.scss']
})
export class HometwoBannerComponent implements OnInit {

    constructor(
        @Inject(AppStore) private store: Redux.Store,
        private route: ActivatedRoute,
        private router: Router
    ) {

    }

    ngOnInit(): void {
        this.resetOption = [this.options[0]];
        globalThis['HometwoBannerComponent'] = this;
        this.getAllData();
    }

    getAllData() {
        this.getLocations();
        this.getMostVisited();
    }

    getLocations() {
        let self: any = this;
        $.get("/API/location/Get_location").then(res => {
            if (res.status) {
                self.setStore({ name: "locations", value: res.data });
            }
        })
    }


    getMostVisited() {
        let self: any = this;
        let defaultListObj = {
            mainImg: [
                {
                    img: ''
                }
            ],
            categoryLink: '',
            category: '',
            bookmarkLink: '',
            location: '',
            title: '',
            price: '',
            detailsLink: 'single-listings',
            authorImg: 'assets/img/user1.jpg',
            openORclose: '',
            extraClass: 'status-open',
            authorName: '',
            rating: [
                {
                    icon: 'bx bxs-star'
                },
                {
                    icon: 'bx bxs-star'
                },
                {
                    icon: 'bx bxs-star'
                },
                {
                    icon: 'bx bxs-star'
                },
                {
                    icon: 'bx bxs-star'
                }
            ],
            ratingCount: '18'
        };
        $.get("/API/adv/Get_home_page_add").then(res => {
            if (res.status) {
                self.setStore({
                    name: "mostvisited",
                    value: res.data.map((o) => {
                        return {
                            ...defaultListObj,
                            title: o.bussion_name,
                            mainImg: [
                                {
                                    img: o.image
                                }
                            ],
                            openORclose: o.open_time_from
                        }
                    })
                });
            }
        });
    }

    search() {
        let self: any = this;
        $.post("/API/adv/Get_add_by_cat_loc", {
            cat: self.category.id,
            loc: self.location.id
        }).then(res => {
            if (res.status) {
                self.setStore({ name: "searchedData", value: res.data });
                self.router.navigateByUrl("/grid-listings-left-sidebar");
            } else {

            }
        });
    }

    bannerContent = [
        {
            title: 'Find Nearby',
            paragraph: 'Expolore top-rated attractions, activities and more...',
            popularSearchList: [
                {
                    title: 'Restaurants',
                    link: 'grid-listings-left-sidebar'
                },
                {
                    title: 'Events',
                    link: 'grid-listings-left-sidebar'
                },
                {
                    title: 'Clothing',
                    link: 'grid-listings-left-sidebar'
                },
                {
                    title: 'Bank',
                    link: 'grid-listings-left-sidebar'
                },
                {
                    title: 'Fitness',
                    link: 'grid-listings-left-sidebar'
                },
                {
                    title: 'Bookstore',
                    link: 'grid-listings-left-sidebar'
                }
            ]
        }
    ]
    bannerImage = [
        {
            img: 'assets/img/banner-img1.png'
        }
    ]

    category: any;
    location: any;

    // Category Select
    singleSelect: any = [];
    multiSelect: any = [];
    stringArray: any = [];
    objectsArray: any = [];
    resetOption: any;
    config = {
        locations: {
            displayKey: "location",
            search: true,
            placeholder: "Select Location",
        },
        category: {
            displayKey: "title",
            search: true,
            placeholder: "Select Category"
        }
    };
    locations: [

    ];
    options = [
        // Type here your category name
        {
            name: "Restaurants",
        },
        {
            name: "Events",
        },
        {
            name: "Clothing",
        },
        {
            name: "Bank",
        },
        {
            name: "Fitness",
        },
        {
            name: "Bookstore",
        }
    ];
    searchChange($event) {
        //console.log($event);
    }
    reset() {
        this.resetOption = [];
    }

}

mixins.connect(HometwoBannerComponent);