import {
 Reducer,
 combineReducers
} from 'redux';

import initialState from "./state";

const mainReduces = function (state = initialState, action) {
  let ret = { ...initialState };
  if (action && action.type && typeof action.type == "function") {
    ret = action.type(state);
  }
  localStorage.setItem("store", JSON.stringify(ret));
  return ret;
};
const rootReducer: Reducer = combineReducers({
  root: mainReduces
});

export default rootReducer;