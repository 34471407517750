import { Component, Inject, OnInit } from '@angular/core';
import mixins from 'src/app/mixins';
import * as Redux from "redux";
import { AppStore } from 'src/app/store/store';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-dashboard-navbar',
  templateUrl: './dashboard-navbar.component.html',
  styleUrls: ['./dashboard-navbar.component.scss']
})
export class DashboardNavbarComponent implements OnInit {

  constructor(@Inject(AppStore) private store: Redux.Store, private route: ActivatedRoute,
    private router: Router) { }

  ngOnInit(): void {
    globalThis.DashboardNavbarComponent = this;
  }

  hasUser() {
    let self: any = this;
    return self.getStore().currentUser;
  }

  logout(e) {
    let self: any = this;
    e.preventDefault();
    self.setStore({ name: "currentUser", value: false });
    self.router.navigateByUrl("/");
  }

}

mixins.connect(DashboardNavbarComponent);