$.ajaxSetup({
    crossDomain: true,
    beforeSend: function (xhr, options) {
        if (options.url.includes("/API")) {
            options.url = "https://desifinds.com.au" + options.url;
        }
    },
    complete: function (arg) {
        //console.log("complete", arg);
    }
})