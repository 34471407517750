import { Component, Inject, OnInit } from '@angular/core';
import mixins from 'src/app/mixins';
import * as Redux from "redux";
import { AppStore } from 'src/app/store/store';

@Component({
  selector: 'app-navbar-style-two',
  templateUrl: './navbar-style-two.component.html',
  styleUrls: ['./navbar-style-two.component.scss']
})
export class NavbarStyleTwoComponent implements OnInit {

  constructor(@Inject(AppStore) private store: Redux.Store) { }

  ngOnInit(): void {
    globalThis['NavbarStyleTwoComponent'] = this;
  }

  hasUser() {
    let self: any = this;
    return self.getStore().currentUser;
  }

  logout(e) {
    let self: any = this;
    e.preventDefault();
    self.setStore({ name: "currentUser", value: false })
  }

}

mixins.connect(NavbarStyleTwoComponent);