<!-- Start Sidemenu Area -->
<div class="sidemenu-area">
  <div class="sidemenu-header">
    <a routerLink="/dashboard" class="navbar-brand d-flex align-items-center">
      <img
        src="assets/img/logo-desifinds.jpeg"
        alt="logo"
        style="height: 40px"
      />
    </a>
    <div class="responsive-burger-menu d-block d-lg-none">
      <span class="top-bar"></span>
      <span class="middle-bar"></span>
      <span class="bottom-bar"></span>
    </div>
  </div>
  <div class="sidemenu-body">
    <ul class="sidemenu-nav metisMenu h-100" id="sidemenu-nav" data-simplebar>
      <li class="nav-item-title">Main</li>
      <li class="nav-item" routerLinkActive="active mm-active">
        <a routerLink="/dashboard" class="nav-link">
          <span class="icon"><i class="bx bx-home-circle"></i></span>
          <span class="menu-title">Dashboard</span>
        </a>
      </li>
      <!-- <li class="nav-item" routerLinkActive="active mm-active">
        <a routerLink="/dashboard-messages" class="nav-link">
          <span class="icon"><i class="bx bx-envelope-open"></i></span>
          <span class="menu-title">Messages</span>
          <span class="badge">3</span>
        </a>
      </li> -->
      <li class="nav-item" routerLinkActive="active mm-active">
        <a routerLink="/dashboard-bookings" class="nav-link">
          <span class="icon"><i class="bx bx-copy"></i></span>
          <span class="menu-title">Bookings</span>
        </a>
      </li>
      <!-- <li class="nav-item" routerLinkActive="active mm-active">
        <a routerLink="/dashboard-wallet" class="nav-link">
          <span class="icon"><i class="bx bx-wallet"></i></span>
          <span class="menu-title">Wallet</span>
        </a>
      </li> -->
      <li class="nav-item-title">Listings</li>
      <li class="nav-item" routerLinkActive="active mm-active">
        <a
          href="javascript:void(0)"
          class="collapsed-nav-link nav-link"
          aria-expanded="false"
        >
          <span class="icon"><i class="bx bx-layer"></i></span>
          <span class="menu-title">My Listings</span>
        </a>
        <ul class="sidemenu-nav-second-level">
          <li class="nav-item" routerLinkActive="active">
            <a routerLink="/dashboard-my-listings" class="nav-link">
              <span class="menu-title">Active</span>
              <span class="badge">5</span>
            </a>
          </li>
          <li class="nav-item" routerLinkActive="active">
            <a routerLink="/dashboard-my-listings" class="nav-link">
              <span class="menu-title">Pending</span>
              <span class="badge yellow">1</span>
            </a>
          </li>
          <li class="nav-item" routerLinkActive="active">
            <a routerLink="/dashboard-my-listings" class="nav-link">
              <span class="menu-title">Expired</span>
              <span class="badge red">2</span>
            </a>
          </li>
        </ul>
      </li>
      <li class="nav-item" routerLinkActive="active mm-active">
        <a routerLink="/dashboard-reviews" class="nav-link">
          <span class="icon"><i class="bx bx-star"></i></span>
          <span class="menu-title">Reviews</span>
        </a>
      </li>
      <!-- <li class="nav-item" routerLinkActive="active mm-active">
        <a routerLink="/dashboard-bookmarks" class="nav-link">
          <span class="icon"><i class="bx bx-heart"></i></span>
          <span class="menu-title">Bookmarks</span>
        </a>
      </li> -->
      <li class="nav-item" routerLinkActive="active mm-active">
        <a routerLink="/dashboard-add-listings" class="nav-link">
          <span class="icon"><i class="bx bx-plus-circle"></i></span>
          <span class="menu-title">Add Listings</span>
        </a>
      </li>
      <li class="nav-item" routerLinkActive="active mm-active">
        <a routerLink="/dashboard-invoice" class="nav-link">
          <span class="icon"><i class="bx bx-certification"></i></span>
          <span class="menu-title">Invoice</span>
        </a>
      </li>
      <li class="nav-item-title">Account</li>
      <li class="nav-item" routerLinkActive="active mm-active">
        <a routerLink="/dashboard-my-profile" class="nav-link">
          <span class="icon"><i class="bx bxs-user-circle"></i></span>
          <span class="menu-title">Profile</span>
        </a>
      </li>
      <li class="nav-item">
        <a routerLink="/" class="nav-link">
          <span class="icon"><i class="bx bx-log-out"></i></span>
          <span class="menu-title">Logout</span>
        </a>
      </li>
    </ul>
  </div>
</div>
<!-- End Sidemenu Area -->
