<app-navbar-style-two></app-navbar-style-two>

<!-- Start Map Area -->
<div id="map">
  <iframe
    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3022.9476519598093!2d-73.99185268459418!3d40.74117737932881!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c259a3f81d549f%3A0xb2a39bb5cacc7da0!2s175%205th%20Ave%2C%20New%20York%2C%20NY%2010010%2C%20USA!5e0!3m2!1sen!2sbd!4v1588746137032!5m2!1sen!2sbd"
  ></iframe>
</div>
<!-- End Map Area -->

<!-- Start Contact Info Area -->
<section class="contact-info-area pt-100 pb-70">
  <div class="container">
    <div class="row">
      <div class="col-lg-4 col-md-6" *ngFor="let Content of contactInfoBox">
        <div class="contact-info-box">
          <div class="back-icon">
            <i class="{{ Content.icon }}"></i>
          </div>
          <div class="icon">
            <i class="{{ Content.icon }}"></i>
          </div>
          <h3>{{ Content.title }}</h3>
          <p *ngFor="let Text of Content.info">{{ Text.text }}</p>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- End Contact Info Area -->

<!-- Start Contact Area -->
<section class="contact-area pb-100">
  <div class="container">
    <div class="section-title" *ngFor="let Title of sectionTitle">
      <h2>{{ Title.title }}</h2>
      <p>{{ Title.paragraph }}</p>
    </div>

    <div class="row">
      <div class="col-lg-6 col-md-12">
        <div class="contact-image" *ngFor="let Image of contactImage">
          <img [src]="Image.img" alt="image" />
        </div>
      </div>

      <div class="col-lg-6 col-md-12">
        <div class="contact-form">
          <form #contactForm="ngForm" (ngSubmit)="submit(contactForm.value)">
            <div class="row">
              <div class="col-lg-12 col-md-6">
                <div class="form-group">
                  <input
                    required
                    minlength="3"
                    maxlength="50"
                    ngModel
                    name="name"
                    type="text"
                    #name="ngModel"
                    class="form-control"
                    id="name"
                    placeholder="Your name"
                  />
                  <div
                    class="alert alert-danger"
                    *ngIf="name.touched && !name.valid"
                  >
                    <div *ngIf="name.errors.required">Name is required.</div>
                    <div *ngIf="name.errors.minlength">
                      Name is minimum
                      {{ name.errors.minlength.requiredLength }} character.
                    </div>
                    <div *ngIf="name.errors.maxlength">
                      Name is maximum 50 character.
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-lg-12 col-md-6">
                <div class="form-group">
                  <input
                    required
                    ngModel
                    name="email"
                    type="text"
                    #email="ngModel"
                    class="form-control"
                    id="email"
                    placeholder="Your email address"
                  />
                  <div
                    class="alert alert-danger"
                    *ngIf="email.touched && !email.valid"
                  >
                    Email Address is required.
                  </div>
                </div>
              </div>

              <div class="col-lg-12 col-md-12">
                <div class="form-group">
                  <input
                    required
                    ngModel
                    name="number"
                    type="text"
                    #number="ngModel"
                    class="form-control"
                    id="number"
                    placeholder="Your phone number"
                  />
                  <div
                    class="alert alert-danger"
                    *ngIf="number.touched && !number.valid"
                  >
                    Phone number is required.
                  </div>
                </div>
              </div>

              <div class="col-lg-12 col-md-12">
                <div class="form-group">
                  <textarea
                    required
                    ngModel
                    #message="ngModel"
                    name="message"
                    id="message"
                    cols="30"
                    rows="6"
                    class="form-control"
                    placeholder="Write your message..."
                  ></textarea>
                  <div
                    class="alert alert-danger"
                    *ngIf="message.touched && !name.valid"
                  >
                    Message is required.
                  </div>
                </div>
              </div>

              <div class="col-lg-12 col-md-12">
                <button
                  type="submit"
                  class="default-btn"
                  [class.disabled]="!contactForm.valid"
                >
                  Send Message
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- End Contact Area -->

<app-footer-style-one></app-footer-style-one>
