let initialState = {
    currentUser: null,
    load: false,
    category: [],
    searchedData: [],
    mostvisited: [],
    category_count: []
};

let store = localStorage.getItem("store");
if (store) {
    try {
        initialState = Object.assign(initialState, JSON.parse(store));
    } catch (e) {
        console.error(e);
    }
}

export default initialState;