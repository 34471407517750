<!-- Start Navbar Area -->
<div class="navbar-area">
  <div class="vesax-responsive-nav">
    <div class="vesax-responsive-menu">
      <div class="responsive-burger-menu d-lg-none d-block">
        <span class="top-bar"></span>
        <span class="middle-bar"></span>
        <span class="bottom-bar"></span>
      </div>
    </div>
  </div>
  <div class="vesax-nav">
    <nav class="navbar navbar-expand-md navbar-light">
      <div class="collapse navbar-collapse mean-menu">
        <ul class="navbar-nav">
          <li class="nav-item">
            <a href="javascript:void(0)" class="dropdown-toggle nav-link"
              >Home</a
            >
            <ul class="dropdown-menu">
              <li class="nav-item">
                <a
                  routerLink="/"
                  class="nav-link"
                  routerLinkActive="active"
                  [routerLinkActiveOptions]="{ exact: true }"
                  >Home Demo - 1</a
                >
              </li>
              <li class="nav-item">
                <a
                  routerLink="/index-2"
                  class="nav-link"
                  routerLinkActive="active"
                  [routerLinkActiveOptions]="{ exact: true }"
                  >Home Demo - 2</a
                >
              </li>
            </ul>
          </li>
          <li class="nav-item">
            <a href="javascript:void(0)" class="dropdown-toggle nav-link"
              >Listings</a
            >
            <ul class="dropdown-menu">
              <li class="nav-item">
                <a href="javascript:void(0)" class="nav-link"
                  >List Layout <i class="bx bx-chevron-right"></i
                ></a>
                <ul class="dropdown-menu">
                  <li class="nav-item">
                    <a
                      routerLink="/vertical-listings-left-sidebar"
                      class="nav-link"
                      routerLinkActive="active"
                      [routerLinkActiveOptions]="{ exact: true }"
                      >Left Sidebar</a
                    >
                  </li>
                  <li class="nav-item">
                    <a
                      routerLink="/vertical-listings-right-sidebar"
                      class="nav-link"
                      routerLinkActive="active"
                      [routerLinkActiveOptions]="{ exact: true }"
                      >Right Sidebar</a
                    >
                  </li>
                  <li class="nav-item">
                    <a
                      routerLink="/vertical-listings-full-width"
                      class="nav-link"
                      routerLinkActive="active"
                      [routerLinkActiveOptions]="{ exact: true }"
                      >Full Width</a
                    >
                  </li>
                </ul>
              </li>
              <li class="nav-item">
                <a href="javascript:void(0)" class="nav-link"
                  >Grid Layout <i class="bx bx-chevron-right"></i
                ></a>
                <ul class="dropdown-menu">
                  <li class="nav-item">
                    <a
                      routerLink="/grid-listings-left-sidebar"
                      class="nav-link"
                      routerLinkActive="active"
                      [routerLinkActiveOptions]="{ exact: true }"
                      >Left Sidebar</a
                    >
                  </li>
                  <li class="nav-item">
                    <a
                      routerLink="/grid-listings-right-sidebar"
                      class="nav-link"
                      routerLinkActive="active"
                      [routerLinkActiveOptions]="{ exact: true }"
                      >Right Sidebar</a
                    >
                  </li>
                  <li class="nav-item">
                    <a
                      routerLink="/grid-listings-full-width"
                      class="nav-link"
                      routerLinkActive="active"
                      [routerLinkActiveOptions]="{ exact: true }"
                      >Full Width</a
                    >
                  </li>
                </ul>
              </li>
              <li class="nav-item">
                <a
                  routerLink="/single-listings"
                  class="nav-link"
                  routerLinkActive="active"
                  [routerLinkActiveOptions]="{ exact: true }"
                  >Listings Details</a
                >
              </li>
              <li class="nav-item">
                <a
                  routerLink="/destinations"
                  class="nav-link"
                  routerLinkActive="active"
                  [routerLinkActiveOptions]="{ exact: true }"
                  >Top Place</a
                >
              </li>
              <li class="nav-item">
                <a
                  routerLink="/categories"
                  class="nav-link"
                  routerLinkActive="active"
                  [routerLinkActiveOptions]="{ exact: true }"
                  >Categories</a
                >
              </li>
              <li class="nav-item">
                <a
                  routerLink="/user-profile"
                  class="nav-link"
                  routerLinkActive="active"
                  [routerLinkActiveOptions]="{ exact: true }"
                  >Author Profile</a
                >
              </li>
            </ul>
          </li>
          <li class="nav-item">
            <a href="javascript:void(0)" class="dropdown-toggle nav-link"
              >User Panel</a
            >
            <ul class="dropdown-menu">
              <li class="nav-item">
                <a
                  routerLink="/dashboard"
                  class="nav-link"
                  routerLinkActive="active"
                  [routerLinkActiveOptions]="{ exact: true }"
                  >Dashboard</a
                >
              </li>
              <li class="nav-item">
                <a
                  routerLink="/dashboard-messages"
                  class="nav-link"
                  routerLinkActive="active"
                  [routerLinkActiveOptions]="{ exact: true }"
                  >Messages</a
                >
              </li>
              <li class="nav-item">
                <a
                  routerLink="/dashboard-bookings"
                  class="nav-link"
                  routerLinkActive="active"
                  [routerLinkActiveOptions]="{ exact: true }"
                  >Bookings</a
                >
              </li>
              <li class="nav-item">
                <a
                  routerLink="/dashboard-wallet"
                  class="nav-link"
                  routerLinkActive="active"
                  [routerLinkActiveOptions]="{ exact: true }"
                  >Wallet</a
                >
              </li>
              <li class="nav-item">
                <a
                  routerLink="/dashboard-my-listings"
                  class="nav-link"
                  routerLinkActive="active"
                  [routerLinkActiveOptions]="{ exact: true }"
                  >My Listings</a
                >
              </li>
              <li class="nav-item">
                <a
                  routerLink="/dashboard-reviews"
                  class="nav-link"
                  routerLinkActive="active"
                  [routerLinkActiveOptions]="{ exact: true }"
                  >Reviews</a
                >
              </li>
              <li class="nav-item">
                <a
                  routerLink="/dashboard-bookmarks"
                  class="nav-link"
                  routerLinkActive="active"
                  [routerLinkActiveOptions]="{ exact: true }"
                  >Bookmarks</a
                >
              </li>
              <li class="nav-item">
                <a
                  routerLink="/dashboard-add-listings"
                  class="nav-link"
                  routerLinkActive="active"
                  [routerLinkActiveOptions]="{ exact: true }"
                  >Add Listings</a
                >
              </li>
              <li class="nav-item">
                <a
                  routerLink="/dashboard-my-profile"
                  class="nav-link"
                  routerLinkActive="active"
                  [routerLinkActiveOptions]="{ exact: true }"
                  >My Profile</a
                >
              </li>
              <li class="nav-item">
                <a
                  routerLink="/dashboard-invoice"
                  class="nav-link"
                  routerLinkActive="active"
                  [routerLinkActiveOptions]="{ exact: true }"
                  >Invoice</a
                >
              </li>
            </ul>
          </li>
          <li class="nav-item">
            <a href="javascript:void(0)" class="dropdown-toggle nav-link"
              >Shop</a
            >
            <ul class="dropdown-menu">
              <li class="nav-item">
                <a
                  routerLink="/products-list"
                  class="nav-link"
                  routerLinkActive="active"
                  [routerLinkActiveOptions]="{ exact: true }"
                  >Products List</a
                >
              </li>
              <li class="nav-item">
                <a
                  routerLink="/cart"
                  class="nav-link"
                  routerLinkActive="active"
                  [routerLinkActiveOptions]="{ exact: true }"
                  >Cart</a
                >
              </li>
              <li class="nav-item">
                <a
                  routerLink="/checkout"
                  class="nav-link"
                  routerLinkActive="active"
                  [routerLinkActiveOptions]="{ exact: true }"
                  >Checkout</a
                >
              </li>
              <li class="nav-item">
                <a
                  routerLink="/single-products"
                  class="nav-link"
                  routerLinkActive="active"
                  [routerLinkActiveOptions]="{ exact: true }"
                  >Products Details</a
                >
              </li>
            </ul>
          </li>
          <li class="nav-item">
            <a href="javascript:void(0)" class="dropdown-toggle nav-link"
              >Blog</a
            >
            <ul class="dropdown-menu">
              <li class="nav-item">
                <a
                  routerLink="/blog-grid"
                  class="nav-link"
                  routerLinkActive="active"
                  [routerLinkActiveOptions]="{ exact: true }"
                  >Blog Grid</a
                >
              </li>
              <li class="nav-item">
                <a
                  routerLink="/blog-right-sidebar"
                  class="nav-link"
                  routerLinkActive="active"
                  [routerLinkActiveOptions]="{ exact: true }"
                  >Blog Right Sidebar</a
                >
              </li>
              <li class="nav-item">
                <a
                  routerLink="/blog-details"
                  class="nav-link"
                  routerLinkActive="active"
                  [routerLinkActiveOptions]="{ exact: true }"
                  >Blog Details</a
                >
              </li>
            </ul>
          </li>
          <li class="nav-item">
            <a href="javascript:void(0)" class="dropdown-toggle nav-link"
              >Pages</a
            >
            <ul class="dropdown-menu">
              <li class="nav-item">
                <a
                  routerLink="/about"
                  class="nav-link"
                  routerLinkActive="active"
                  [routerLinkActiveOptions]="{ exact: true }"
                  >About Us</a
                >
              </li>
              <li class="nav-item">
                <a
                  routerLink="/how-it-works"
                  class="nav-link"
                  routerLinkActive="active"
                  [routerLinkActiveOptions]="{ exact: true }"
                  >How It Work</a
                >
              </li>
              <li class="nav-item">
                <a
                  routerLink="/pricing"
                  class="nav-link"
                  routerLinkActive="active"
                  [routerLinkActiveOptions]="{ exact: true }"
                  >Pricing</a
                >
              </li>
              <li class="nav-item">
                <a
                  routerLink="/gallery"
                  class="nav-link"
                  routerLinkActive="active"
                  [routerLinkActiveOptions]="{ exact: true }"
                  >Gallery</a
                >
              </li>
              <li class="nav-item">
                <a href="javascript:void(0)" class="nav-link"
                  >Events <i class="bx bx-chevron-right"></i
                ></a>
                <ul class="dropdown-menu">
                  <li class="nav-item">
                    <a
                      routerLink="/events"
                      class="nav-link"
                      routerLinkActive="active"
                      [routerLinkActiveOptions]="{ exact: true }"
                      >Events</a
                    >
                  </li>
                  <li class="nav-item">
                    <a
                      routerLink="/single-events"
                      class="nav-link"
                      routerLinkActive="active"
                      [routerLinkActiveOptions]="{ exact: true }"
                      >Events Details</a
                    >
                  </li>
                </ul>
              </li>
              <li class="nav-item">
                <a
                  routerLink="/faq"
                  class="nav-link"
                  routerLinkActive="active"
                  [routerLinkActiveOptions]="{ exact: true }"
                  >FAQ</a
                >
              </li>
              <li class="nav-item">
                <a
                  routerLink="/error"
                  class="nav-link"
                  routerLinkActive="active"
                  [routerLinkActiveOptions]="{ exact: true }"
                  >404 Error Page</a
                >
              </li>
              <li class="nav-item">
                <a
                  routerLink="/coming-soon"
                  class="nav-link"
                  routerLinkActive="active"
                  [routerLinkActiveOptions]="{ exact: true }"
                  >Coming Soon</a
                >
              </li>
              <li class="nav-item">
                <a
                  routerLink="/contact"
                  class="nav-link"
                  routerLinkActive="active"
                  [routerLinkActiveOptions]="{ exact: true }"
                  >Contact</a
                >
              </li>
            </ul>
          </li>
        </ul>
        <div class="others-option d-flex align-items-center">
          <div class="option-item">
            <div
              class="dropdown profile-nav-item"
              *ngIf="hasUser(); else elseBlock"
            >
              <a
                href="#"
                class="dropdown-toggle"
                role="button"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <div class="menu-profile">
                  <img
                    src="assets/img/user1.jpg"
                    class="rounded-circle"
                    alt="image"
                  />
                  <span class="name">My Account</span>
                </div>
              </a>
              <div class="dropdown-menu">
                <div
                  class="dropdown-header d-flex flex-column align-items-center"
                >
                  <div class="figure mb-3">
                    <img
                      src="assets/img/user1.jpg"
                      class="rounded-circle"
                      alt="image"
                    />
                  </div>

                  <div class="info text-center">
                    <span class="name">{{
                      getStore().currentUser.name || "Ananomous"
                    }}</span>
                    <p class="mb-3 email">{{ getStore().currentUser.email }}</p>
                  </div>
                </div>
                <div class="dropdown-body">
                  <ul class="profile-nav p-0 pt-3">
                    <li class="nav-item">
                      <a routerLink="/dashboard" class="nav-link"
                        ><i class="bx bx-user"></i> <span>Dashboard</span></a
                      >
                    </li>
                    <li class="nav-item">
                      <a routerLink="/dashboard-messages" class="nav-link"
                        ><i class="bx bx-envelope"></i> <span>Messages</span></a
                      >
                    </li>
                    <li class="nav-item">
                      <a routerLink="/dashboard-bookings" class="nav-link"
                        ><i class="bx bx-edit-alt"></i> <span>Bookings</span></a
                      >
                    </li>
                    <li class="nav-item">
                      <a routerLink="/dashboard-my-profile" class="nav-link"
                        ><i class="bx bx-cog"></i> <span>Settings</span></a
                      >
                    </li>
                  </ul>
                </div>
                <div class="dropdown-footer">
                  <ul class="profile-nav">
                    <li class="nav-item">
                      <a (click)="logout($event)" class="nav-link">
                        <i class="bx bx-log-out"></i>
                        <span>Logout</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <ng-template #elseBlock>
              <div class="others-option d-flex align-items-center">
                <div class="option-item">
                  <span
                    data-bs-toggle="modal"
                    data-bs-target="#loginRegisterModal"
                    ><i class="flaticon-user"></i> Login / Register</span
                  >
                </div>
              </div>
            </ng-template>
          </div>
          <!-- <div class="option-item">
            <a routerLink="/dashboard-add-listings" class="default-btn"
              ><i class="flaticon-more"></i> Add Listing</a
            >
          </div> -->
        </div>
      </div>
    </nav>
  </div>
  <div class="others-option-for-responsive">
    <div class="container">
      <div class="dot-menu">
        <div class="inner">
          <div class="circle circle-one"></div>
          <div class="circle circle-two"></div>
          <div class="circle circle-three"></div>
        </div>
      </div>
      <div class="container">
        <div class="option-inner">
          <div class="others-option">
            <div class="option-item">
              <div class="dropdown profile-nav-item">
                <a
                  href="#"
                  class="dropdown-toggle"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <div class="menu-profile">
                    <img
                      src="assets/img/user1.jpg"
                      class="rounded-circle"
                      alt="image"
                    />
                    <span class="name">My Account</span>
                  </div>
                </a>
                <div class="dropdown-menu">
                  <div
                    class="
                      dropdown-header
                      d-flex
                      flex-column
                      align-items-center
                    "
                  >
                    <div class="figure mb-3">
                      <img
                        src="assets/img/user1.jpg"
                        class="rounded-circle"
                        alt="image"
                      />
                    </div>
                    <div class="info text-center">
                      <span class="name">Andy Smith</span>
                      <p class="mb-3 email">hello@androsmith.com</p>
                    </div>
                  </div>
                  <div class="dropdown-body">
                    <ul class="profile-nav p-0 pt-3">
                      <li class="nav-item">
                        <a routerLink="/dashboard" class="nav-link"
                          ><i class="bx bx-user"></i> <span>Dashboard</span></a
                        >
                      </li>
                      <li class="nav-item">
                        <a routerLink="/dashboard-messages" class="nav-link"
                          ><i class="bx bx-envelope"></i>
                          <span>Messages</span></a
                        >
                      </li>
                      <li class="nav-item">
                        <a routerLink="/dashboard-bookings" class="nav-link"
                          ><i class="bx bx-edit-alt"></i>
                          <span>Bookings</span></a
                        >
                      </li>
                      <li class="nav-item">
                        <a routerLink="/dashboard-my-profile" class="nav-link"
                          ><i class="bx bx-cog"></i> <span>Settings</span></a
                        >
                      </li>
                    </ul>
                  </div>
                  <div class="dropdown-footer">
                    <ul class="profile-nav">
                      <li class="nav-item">
                        <a routerLink="/" class="nav-link"
                          ><i class="bx bx-log-out"></i> <span>Logout</span></a
                        >
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <!-- <div class="option-item" *ngIf="hasUser()">
              <a routerLink="/dashboard-add-listings" class="default-btn"
                ><i class="flaticon-more"></i> Add Listing</a
              >
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- End Navbar Area -->
