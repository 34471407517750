<app-navbar-style-two></app-navbar-style-two>

<!-- Start Page Title Area -->
<div
  class="page-title-area"
  *ngFor="let Content of pageTitleContent"
  style="background-image: url({{ Content.backgroundImage }});"
>
  <div class="container">
    <div class="page-title-content">
      <h2>{{ Content.title }}</h2>
      <ul>
        <li><a routerLink="/">Home</a></li>
        <li>{{ Content.title }}</li>
      </ul>
    </div>
  </div>
</div>
<!-- End Page Title Area -->

<!-- Start About Area -->
<section class="about-area ptb-100">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-6 col-md-12">
        <div class="about-content" *ngFor="let Content of aboutContent">
          <h2>{{ Content.title }}</h2>
          <span
            ><strong>{{ Content.subTitle }}</strong></span
          >
          <p *ngFor="let Paragraph of Content.paragraph">
            {{ Paragraph.text }}
          </p>
        </div>
      </div>

      <div class="col-lg-6 col-md-12">
        <div class="about-image" *ngFor="let Image of aboutImage">
          <img [src]="Image.img" alt="image" />
          <a href="{{ Image.videoLink }}" class="video-btn popup-youtube"
            ><i class="bx bx-play"></i
          ></a>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- End About Area -->

<section class="funfacts-area pb-70">
  <app-funfacts></app-funfacts>
</section>

<section class="team-area pt-100 pb-70 bg-f9f9f9">
  <app-team></app-team>
</section>

<section class="how-it-works-area pt-100 pb-70">
  <app-how-it-works></app-how-it-works>
</section>

<div class="partner-area ptb-100 bg-f5f5f5">
  <app-partner></app-partner>
</div>

<section class="feedback-area bg-f9f9f9 ptb-100">
  <app-feedback-style-one></app-feedback-style-one>
</section>

<app-footer-style-one></app-footer-style-one>
