import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-ad-columns',
  templateUrl: './ad-columns.component.html',
  styleUrls: ['./ad-columns.component.scss']
})
export class AdColumnsComponent implements OnInit {

  @Input() position:string = 'left';

  ads = [{}, {}, {}, {}, {}, {}, {}, {}];
  constructor() { }

  ngOnInit(): void {
  }

}
