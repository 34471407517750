<div
  class="modal fade loginRegisterModal"
  id="loginRegisterModal"
  tabindex="-1"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <button
        type="button"
        class="close"
        data-bs-dismiss="modal"
        aria-label="Close"
      >
        <i class="bx bx-x"></i>
      </button>
      <ul class="nav nav-tabs" id="myTab" role="tablist">
        <li class="nav-item">
          <button
            class="nav-link active"
            id="login-tab"
            data-bs-toggle="tab"
            data-bs-target="#login"
            type="button"
            role="tab"
            aria-controls="login"
            aria-selected="true"
          >
            Login
          </button>
        </li>
        <li class="nav-item">
          <button
            class="nav-link"
            id="register-tab"
            data-bs-toggle="tab"
            data-bs-target="#register"
            type="button"
            role="tab"
            aria-controls="register"
            aria-selected="true"
          >
            Register
          </button>
        </li>
      </ul>
      <div class="tab-content" id="myTabContent">
        <div class="tab-pane fade show active" id="login" role="tabpanel">
          <div class="vesax-login">
            <!-- <div class="login-with-account">
              <span>Login with</span>
              <ul>
                <li>
                  <a href="#" class="facebook"
                    ><i class="bx bxl-facebook"></i> Facebook</a
                  >
                </li>
                <li>
                  <a href="#" class="twitter"
                    ><i class="bx bxl-twitter"></i> Twitter</a
                  >
                </li>
              </ul>
            </div> -->
            <span class="sub-title"><span>login with</span></span>
            <form #login="ngForm" (ngSubmit)="onSubmit(login)">
              <div class="form-group">
                <input
                  ngModel
                  name="email"
                  type="text"
                  placeholder="Username or Email"
                  class="form-control"
                />
              </div>
              <div class="form-group">
                <input
                  ngModel
                  name="pass"
                  type="password"
                  placeholder="Password"
                  class="form-control"
                />
              </div>
              <button type="submit">Login Now</button>
            </form>
            <span class="dont-account"
              >Don't have an account? <a href="#">Register Now</a></span
            >
          </div>
        </div>
        <div class="tab-pane fade" id="register" role="tabpanel">
          <app-register-model></app-register-model>
        </div>
      </div>
    </div>
  </div>
</div>
