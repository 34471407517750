import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-footer-style-one',
    templateUrl: './footer-style-one.component.html',
    styleUrls: ['./footer-style-one.component.scss']
})
export class FooterStyleOneComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

    appDownloadContent = [
        {
            title: 'Download desiFinds App',
            paragraph: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
            btnBox: [
                {
                    img: 'assets/img/play-store.png',
                    text: 'GET IT ON',
                    subText: 'Google Play',
                    link: '#'
                },
                {
                    img: 'assets/img/apple-store.png',
                    text: 'Download on the',
                    subText: 'Apple Store',
                    link: '#'
                }
            ]
        }
    ]

}